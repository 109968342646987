import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { nanoid } from "nanoid";
import { AutoCompleteOptionDataType } from "./components/autocomplete-option-component";

const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: "https://test.copilottravelengineapi.com/v1-0",
  });

  const authLink = setContext((_, { headers }) => {
    const token = "zpka_0c4603af50bc4860b403f73d47684422_1d73e81e";
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export const SEARCH_AIRPORT_CITIES_GQL = gql`
  query SearchAirportsAndCities($query: String!) {
    SearchAirportsAndCities(query: $query) {
      hits {
        id
        type
        name {
          full
          short
        }
        codes {
          iata
          icao
        }
        _geoloc {
          lat
          lng
        }
        timezone {
          name
          offset
        }
        city {
          code
          name
          state
          stateCode
          country
          countryCode
          postalCode
        }
        address
      }
      nbHits
      page
      nbPages
      hitsPerPage
    }
  }
`;

export const getSearchRecordsFromQueryResult = (result: any) => {
  let records: any[] = [];
  if (
    result &&
    result.SearchAirportsAndCities &&
    result.SearchAirportsAndCities.hits &&
    Array.isArray(result.SearchAirportsAndCities.hits)
  ) {
    records = result.SearchAirportsAndCities.hits as any[];
  }
  return records;
};

export const getAutoCompletePropsFromAirportSearchRecord = (record: any) => {
  const l1 = `${record.city.name} (${record.city.code})`;
  const l2 = `(${record.city.code}) ${record.name.full}`;
  const l3 = `${(record.city.name as string).toLowerCase()}, ${(
    record.city.country as string
  ).toLowerCase()}`;
  let l4 = `${l1 ? `${l1} ` : ""}${l2 ? `${l2} ` : ""}${l3 ? `${l3} ` : ""}`;
  l4 = Array.from(new Set(l4.toLowerCase().split(" "))).join(" "); // remove duplicates
  return {
    listId: nanoid(),
    id: record.id,
    label: l1,
    labelTwo: l2,
    labelThree: l3,
    listLabel: l4,
  } as AutoCompleteOptionDataType;
};

export const getOptionsContainingSubstring = (
  options: AutoCompleteOptionDataType[],
  substring: string
) => {
  if (substring === "") return options;

  substring = substring.toLowerCase();
  return options.filter((option: AutoCompleteOptionDataType) => {
    const label = option.listLabel!.toLowerCase();
    return label.includes(substring) ? option : null;
  });
};

export default createApolloClient;
