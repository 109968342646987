import * as React from "react";
import { Box, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import FlightIcon from "@mui/icons-material/Flight";

export type AutoCompleteOptionDataType = {
  listId: string;
  id: string;
  label: string;
  labelTwo?: string;
  labelThree?: string;
  listLabel?: string;
};

interface AutoCompleteOptionProps {
  option: AutoCompleteOptionDataType;
}

const OPTION_COLOR = "#344054";
const OPTION_COLOR2 = "#b5b5b5";

export default function AutoCompleteOptionComponent({
  option,
}: AutoCompleteOptionProps) {
  return (
    <React.Fragment>
      {/* label */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginY: "0.5rem",
        }}
      >
        {/* icon */}
        <Box sx={{ display: "flex", width: "2rem" }}>
          <PlaceIcon sx={{ color: OPTION_COLOR, fontSize: "24px" }} />
        </Box>
        {/* labels */}
        <Box
          sx={{
            flexDirection: "column",
            flex: "1",
          }}
        >
          <Typography fontSize={15} color={OPTION_COLOR}>
            {option.label}
          </Typography>
        </Box>
      </Box>
      {/* label Two and Three */}
      <Box sx={{ display: "flex", width: "100%", paddingLeft: "1rem" }}>
        {/* icon */}
        <Box sx={{ display: "flex", width: "2rem" }}>
          <FlightIcon sx={{ color: { OPTION_COLOR }, fontSize: "24x" }} />
        </Box>
        {/* labels */}
        <Box
          sx={{
            flexDirection: "column",
            flex: "1",
          }}
        >
          <Typography fontSize={14} color={OPTION_COLOR}>
            {option.labelTwo}
          </Typography>
          <Typography
            fontSize={14}
            color={OPTION_COLOR2}
            textTransform={"capitalize"}
          >
            {option.labelThree}
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
}
