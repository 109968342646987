"use client";
import { useEffect, useRef } from "react";
import { Box, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useLazyQuery } from "@apollo/client";

import AutocompleteComponent from "../autocomplete-component";
import { AutoCompleteOptionDataType } from "../autocomplete-option-component";
import {
  SEARCH_AIRPORT_CITIES_GQL,
  getSearchRecordsFromQueryResult,
  getAutoCompletePropsFromAirportSearchRecord,
} from "../../apollo-client";

export default function AirportSearchComponent() {
  const locations: AutoCompleteOptionDataType[] = [];
  const locationsRef = useRef(locations);

  const [getData, { loading, error, data }] = useLazyQuery(
    SEARCH_AIRPORT_CITIES_GQL,
    {
      variables: { query: "" }, // "PAR"
    }
  );

  if (data) {
    const records = getSearchRecordsFromQueryResult(data);
    if (records && records.length) {
      const autoRecords: AutoCompleteOptionDataType[] = records.map(
        (record) => {
          return getAutoCompletePropsFromAirportSearchRecord(record);
        }
      );
      locationsRef.current = autoRecords;
    }
  }

  const onBtnSwitchFromToBtnClicked = () => {
    // getData();
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const showAutocompleteViews = () => {
    return !loading && !error && locationsRef.current.length ? true : false;
  };

  const showNoDataView = () => {
    if (loading || error) return false;
    if (locationsRef.current.length === 0) return true;
    return false;
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "auto",
        width: "auto",
        borderRadius: "24px",
        padding: "15px 20px",
        background: "white",
        maxWidth: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          rowGap: "1.5rem",
          alignItems: "center",
          width: "100%",
          minHeight: "61px",
          minWidth: "300px",
        }}
      >
        {/* fetching */}
        {loading && <CircularProgress />}
        {/* error */}
        {error && (
          <Typography variant="h6" color={"red"}>
            {error.message}
          </Typography>
        )}
        {/* data */}
        {showAutocompleteViews() && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AutocompleteComponent
                options={locationsRef.current}
                inputLabel={"From"}
              ></AutocompleteComponent>
              <IconButton
                sx={{ marginX: "2rem" }}
                onClick={onBtnSwitchFromToBtnClicked}
              >
                <Tooltip title="Switch Source & Destination">
                  <CachedIcon />
                </Tooltip>
              </IconButton>
            </Box>
            <AutocompleteComponent
              options={locationsRef.current}
              inputLabel={"To"}
            ></AutocompleteComponent>
          </>
        )}
        {/* no data */}
        {showNoDataView() && (
          <Typography variant="h6" color={"GrayText"}>
            {"No Data Available"}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
