import { useRef, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";

import AutoCompleteOptionComponent, {
  AutoCompleteOptionDataType,
} from "../autocomplete-option-component";

import {
  SEARCH_AIRPORT_CITIES_GQL,
  getSearchRecordsFromQueryResult,
  getAutoCompletePropsFromAirportSearchRecord,
  getOptionsContainingSubstring,
} from "../../apollo-client";

import { useLazyQuery } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";

interface AutoCompleteProps {
  inputLabel: string;
  options: AutoCompleteOptionDataType[];
}

export default function AutocompleteComponent({
  inputLabel,
  options,
}: AutoCompleteProps) {
  // options
  const stateOptions: AutoCompleteOptionDataType[] = [...options];
  const stateOptionsRef = useRef(stateOptions);

  const [selectedOption, setSelectedOption] = useState<
    AutoCompleteOptionDataType | null | undefined
  >(null);

  // search input
  const [searchValue, setSearchValue] = useState("");
  const debounced = useDebouncedCallback(
    (value) => {
      setSearchValue(value);
    },
    500 // delay in ms
  );

  // API
  const [getData, { data, loading }] = useLazyQuery(SEARCH_AIRPORT_CITIES_GQL, {
    variables: { query: searchValue && searchValue !== "0" ? searchValue : "" }, // "PAR"
  });

  if (data) {
    const records = getSearchRecordsFromQueryResult(data);
    if (records && records.length) {
      const autoRecords: AutoCompleteOptionDataType[] = records.map(
        (record) => {
          return getAutoCompletePropsFromAirportSearchRecord(record);
        }
      );
      stateOptionsRef.current = [...stateOptions, ...autoRecords];
    }
  }

  useEffect(() => {
    if (searchValue) {
      getData(); // use search value as query in get data
    } else {
      stateOptionsRef.current = options; // if text is empty than autocomplete options = options in props
    }
  }, [searchValue, options, getData]);

  return (
    <Autocomplete
      loading={loading}
      onInputChange={(e) => debounced((e.target as HTMLInputElement).value)}
      value={selectedOption!}
      onChange={(event: any, newValue: any) => {
        setSelectedOption(newValue);
      }}
      id="auto-complete"
      //freeSolo
      disableClearable
      options={stateOptionsRef.current}
      getOptionKey={(option: AutoCompleteOptionDataType | any) => option.listId}
      getOptionLabel={(option: AutoCompleteOptionDataType | any) =>
        `${option.label}`
      }
      isOptionEqualToValue={(option, value) => option.valueOf === value.valueOf}
      sx={{ width: "300px" }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label={inputLabel}
          InputProps={{
            ...params.InputProps,
            // disableUnderline: true, // works with 'standard variant'
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            {...props}
            key={option.listId}
            sx={{
              flexDirection: "column",
              width: "100%",
            }}
          >
            <AutoCompleteOptionComponent option={option} />
          </Box>
        );
      }}
      filterOptions={(options: AutoCompleteOptionDataType[]) =>
        getOptionsContainingSubstring(
          options,
          searchValue && searchValue !== "0" ? searchValue : ""
        )
      }
    />
  );
}
