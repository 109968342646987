import "./App.css";

import AirportSearchComponent from "./components/airport-search-component";
import { ApolloProvider } from "@apollo/client";
import createApolloClient from "./apollo-client";

function App() {
  const client = createApolloClient();

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <AirportSearchComponent />
      </ApolloProvider>
    </div>
  );
}

export default App;
